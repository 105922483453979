import { stringifyQueryString } from '@shein/common-function'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import searchFilterAdapter from 'public/src/pages/components/FilterBar/adapter/searchFilterAdapter.js'
import { ROUTE_REGEX } from 'public/src/pre_requests/modules/productList/constant.js'

class BffAdapter {
  constructor({ data, requestType, query, route, constantData, lastListData }) {
    this.data = data
    this.requestType = requestType || ''
    this.query = query
    this.$route = route
    this.constantData = constantData || {}
    this.#lastListData = lastListData || {}
  }

  // 上一次的数据，比如上一次的catInfo
  #lastListData = {}

  get #cccFeedbackInfo() {
    return this.data.bffCccFeedbackInfo || {}
  }

  get #bffProductsInfo() {
    return this.data.bffProductsInfo || {}
  }

  get #bffFilterInfo() {
    return this.data.bffFilterInfo || {}
  }

  get #lang() {
    let lang = 'us'
    if (this.constantData?.lang) {
      lang = this.constantData.lang
    } else if (typeof window !== 'undefined') {
      lang = window.gbCommonInfo.lang
    }
    return lang
  }

  get #listDiffParams() {
    const diffParams = this.#getListDiffParams()
    return {
      ...diffParams,
      isSearchPage: diffParams.type === 'search', // 搜索结果页
      isRealPage: diffParams.type === 'entity', // 真实分类页
      isSelectPage: diffParams.type === 'selection', // 选品页
      isPickPage: diffParams.type === 'pick', // 信息流落地页
    }
  }

  // 字段适配（bff字段 -> 原来的字段）
  adaptData() {
    this.#adaptCatInfo()
    this.#adaptGoods()
    this.#adaptGoodsOtherParameters()
    this.#adaptCardConfig()
    this.#adaptFilters()
    this.#adaptFilterAbtParam()
    this.#adaptCccCommonComponent()
    this.#adaptFlowData()

    if (this.data.cat_info?.isSearchPage) {
      this.#adaptSearchKeywords()
    }
  }
  adaptInitOrFilterData() {
    this.#adaptCatInfo()
    this.#adaptFilters()
  }
  adaGoodsInitData() {
    this.#adaptCatInfo()
    this.#adaptGoods()
    this.#adaptGoodsOtherParameters()
    this.#adaptCardConfig()
    this.#adaptFilterAbtParam()
    this.#adaptCccCommonComponent()
    this.#adaptFlowData()

    if (this.data.cat_info?.isSearchPage) {
      this.#adaptSearchKeywords()
    }
  }

  // 构造searchResultInterfaceCode
  adpatSearchResultInterfaceCode() {
    if (!this.#hasBffProductsInfo()) return

    const code = this.#bffProductsInfo.code
    const buriedPoint = this.#bffProductsInfo.buriedPoint

    if (buriedPoint) {
      return this.data.searchResultInterfaceCode = buriedPoint
    }

    if (code === '0') {
      return this.data.searchResultInterfaceCode = 1
    }
    
    return this.data.searchResultInterfaceCode = '4_1001'
  }
  
  #hasBffProductsInfo() {
    return Object.keys(this.#bffProductsInfo)?.length
  }

  #hasBffFilterInfo() {
    return Object.keys(this.#bffFilterInfo)?.length
  }

  #getListDiffParams() {
    const path =  this.$route?.path || ''

    // 真实分类页对应的真实分类id
    const productBffCateId = this.#bffProductsInfo?.cateId
    // 选品页对应的selectId
    const filterBffSelectId = this.#bffProductsInfo?.category_id
    // 选品页对应的选品真实分类id
    const filterBffCateId = this.#bffFilterInfo?.cateId
    // 搜索结果匹配的cateid
    // this.data.#bffProductsInfo?.cate_ids?.[0]  搜索结果匹配的cateid
    const searchCateId = this.#bffProductsInfo?.cate_ids?.[0] 

    // 搜索页
    const search = path.match?.(ROUTE_REGEX.search)
    if (search) {
      return {
        type: 'search',
        pageName: 'page_search',
        cat_id: productBffCateId || searchCateId || '',
        searchWord: this.#bffProductsInfo?.origin_words || decodeURIComponent(search[1]).slice(0, 150), // search[1] 为搜索词，用来兜底origin_words为空的情况
      }
    }

    // 真实列表页
    const isRealList = path.match(ROUTE_REGEX.real)
    if (isRealList) {
      return {
        type: 'entity',
        pageName: 'page_real_class', 
        entity_id: productBffCateId || isRealList[1],
        cat_id: productBffCateId || searchCateId || '',
      }
    }

    // 选品列表页
    const isSelectList = path.match(ROUTE_REGEX.select)
    if (isSelectList) {
      return {
        type: 'selection',
        pageName: 'page_select_class',
        select_id: filterBffSelectId || isSelectList[3],
        selection_entity_id: filterBffCateId || '',
      }
    }
  
    // 信息流落地页
    const isPickedList = path.match(ROUTE_REGEX.picked)
    if (isPickedList) {
      return {
        type: 'pick',
        pageName: 'page_picked',
        pageStyleKey: 'home_page_feed'
      }
    }

    // 用户行为落地页
    const isUserBehaviorList = path.match(ROUTE_REGEX.pickedProducts)
    if (isUserBehaviorList) {
      // const { carrierSubType, contentCarrierId, entranceGoodsId } = this.data.cat_info
      return {
        ...this.$route?.query
      }
    }

    return {}
  }

  // 成人用品
  #adaptAdultProduct(isSearchPage) {
    if (this.query?.page > 1) {
      // @tips 第二页不请求筛选数据会影响成人弹窗状态
      return this.#lastListData.catInfo?.hasAdultProduct
    }
    const { hasAdultProduct } = this.data.cat_info || {}
    const show_adult_tip = isSearchPage ?
      this.#bffProductsInfo?.show_adult_tip :
      this.data?.bffFilterInfo?.show_adult_tip || this.data?.bffFilterInfo?.showAdultTip
    return hasAdultProduct && (show_adult_tip && show_adult_tip !== '0')
  }

  // 构造cat_info
  #adaptCatInfo() {
    /** 
     * 一些待做的值，此次搜索融合先不做
     * entity_id catInfo.entity_id || catInfo.select_id
     * select_id catInfo.entity_id || catInfo.select_id return this.catInfo?.select_id
     * hasAdultProduct 真实\选品 和 搜索 不一样
     * showFeedbackRec: this.catInfo.showFeedbackRec !== 0
     * 
     * 
     * 一些值还是从node取，暂时不融合 TODO:
     * hasAdultProduct: this.hasAdultProduct(this.Request, this.data.cate_ids, this.data.cate_tags), 有些xhr接口的更新逻辑，暂时不融合
     * */
  
    if (!(this.#hasBffProductsInfo() || this.#hasBffFilterInfo())) return

    const isDefaultChild = this.data?.bffFilterInfo?.tagChild?.defaultChild?.child_id // 默认kids流程

    const listParams = this.#listDiffParams
    const cat_info = {
      ...listParams,
      suggestedSaleTypeBff: this.#bffProductsInfo?.suggestedSaleType || '',
      hasAdultProduct: this.#adaptAdultProduct(listParams.isSearchPage),
      isAdultRankSsrBff: this.data.cat_info.isAdultRankSsrBff,
      requestType: this.requestType,
      page: this.query.page,
      limit: this.query.limit,
      search_type: this.query.search_type,
      tsp_ids: this.query.tsp_ids,
      tag_ids: this.query.tag_ids || isDefaultChild ? this.data.cat_info.tag_ids : '', // 有默认的tagChild时，tag_ids取值
      min_price: this.query.min_price,
      max_price: this.query.max_price,
      sort: this.query.sort,
      attr_values: this.query.attr_values,
      attr_ids: this.query.attr_ids,
      brand_ids: this.query.brand_ids,
      attr_node_ids: this.query.attr_node_ids,
      child_cat_id: this.query.child_cat_id,
      daily: this.query.daily,
      switchBff: this.data.cat_info.switchBff,
      ici: this.query.ici,
      sil_type: this.query.sil_type,
      ShowTip: this.query.ShowTip,
      child_cat_id_void: this.query.search_id ? true : false, 
      search_redir: this.query.search_redir,
      filterWords: this.query.filterWords,
      filterAttrIds: this.query.filterAttrIds,
      store_code: this.query.store_code,
      intent_channel_id: this.query.intent_channel_id,
      url_from: this.query.url_from,
      src_identifier: this.query.src_identifier,
      src_module: this.query.src_module,
      src_tab_page_id: this.query.src_tab_page_id,
      userpath: this.query.userpath,
      is_from_list_feeds: this.query.is_from_list_feeds,
      result_Ioc: this.query.result_Ioc,
      list_cat_name: this.query.list_cat_name,
      lockMall: this.query.lockMall,
      mallCode: this.query.mallCode,
      styleType: this.query.styleType,
      pageListType: this.#bffProductsInfo?.context?.page_list_type || 4,
      sortScene: this.#bffProductsInfo?.sortPoskey,

      pic_nav_id: this.query.pic_nav_id,
      pic_nav_type: this.query.pic_nav_type,
      pic_nav_pos: this.query.pic_nav_pos,
      pic_nav_adp: this.query.pic_nav_adp,
      pic_nav_name: this.query.pic_nav_name,

      pic_tab_id: this.query.pic_tab_id,
      pic_tab_type: this.query.pic_tab_type,
      pic_tab_pos: this.query.pic_tab_pos,

      nav_query_pos: this.query.nav_query_pos,
      nav_query_type: this.query.nav_query_type,
      nav_query_id: this.query.nav_query_id,
      nav_query_adp: this.query.nav_query_adp,
      nav_query_rec_type: this.query.nav_query_rec_type,
      nav_query_name: this.query.nav_query_name,
      nav_to_query: this.query.nav_to_query, 

      kid_id: this.query.kid_id || isDefaultChild ? this.data.cat_info.kid_id : '',
      pic_rec_type: this.query.pic_rec_type,
      pic_cate_id: this.query.pic_cate_id,
      nav_jump_type: this.query.nav_jump_type,
      showAllCategory: this.query.showAllCategory,
      fromPageType: this.query.fromPageType,
      entranceType: this.query.entranceType,
      pageFrom: this.query.pageFrom,
      from_page_cate_id: this.query.from_page_cate_id,
      isClickRefresh: this.query.isClickRefresh,
      divideTime: this.query.divideTime,
    }

    Object.keys(cat_info).forEach(key => {
      if ([undefined, null].includes(cat_info[key])) {
        delete cat_info[key]
      }
    })

    this.data.cat_info = cat_info
  }
  
  // 构造searchKeywords - S
  #checkRedirection ({ action, page_type, page_id, url }) {
    const { PICKS_LINKS, langPath } = gbCommonInfo || {}
    if (page_type == 40) { // 付费会员没有url地址， 提前判断
      url = '/user/prime'
    }
    // cccx url字段只会返回当前配置的page_id，用这个来进行匹配....
    if (page_type == 41) {
      let picksTable = PICKS_LINKS || {}
      let pageId = page_id?.split('/')?.[0] || url
      url = picksTable[pageId] ? `/${picksTable[pageId]}.html` : ''
    }
    // 列表链接分类说明参见： wiki.pageId=192285255
    const page_types = [
      1, // 'search'搜索结果页
      2, // 'real'真实分类页
      // 3, // '-废弃virtual-'
      4, // 'webLink'
      5, // 'itemPicking' 选品分类
      6, // 'sku' 商详
      7, // 'article' 文章
      8, // 'activity' 专题
      9, // 'game' 游戏
      10, // 'lookBook' 套装lookbook
      // 11, // app 专用
      12, // 'store' 第三方商铺
      39, // '新专题'
      40, // '付费会员'
      26, // Category
      16, // DailyNew
      37, // Flashsale
      25, //优惠券中心 
      41, // sheinpick
    ]
    if (action !== 'redirect' || !url || !page_types.includes(+page_type)) return null
  
    // 拼接完整路径
    url = /^\/[^\/]/.test(url) ? `${langPath}${url}` : `//${url}`
  
    const mall_code = {}
    if (page_type == 2 || page_type == 5 || page_type == 41) {
      const mallCode = page_id?.split('/')?.[1]
      mallCode && Object.assign(mall_code, { mallCode })
    }
    const queryObj = {
      search_redir: 1,
      search_words: this.data?.cat_info?.searchWord,
      ici: this.data?.cat_info?.ici?.replace('`d0`', '`d1`'),
      src_identifier: this.data?.cat_info?.src_identifier || '',
      src_tab_page_id: this.data?.cat_info?.src_tab_page_id || '',
      src_module: this.data?.cat_info?.src_module || '',
      ...mall_code
    }
  
    if (page_type == 12) {
      url = `${langPath}/store/home`
      queryObj.store_code = page_id
    }
    return `${url}${url.includes('?') ? '&' : '?'}${stringifyQueryString({ queryObj })}` // 生成最终的
  }
  #adaptSearchKeywords() {
    if (!this.#hasBffProductsInfo()) return
  
    const { searchWord } = this.data?.cat_info || {}
    const { is_suggest_res, suggest_words, origin_num, double_lang_correct } = this.#bffProductsInfo
    const escapedSearchWord = searchWord?.replace('<', '&lt;').replace('>', '&gt;')

    this.data.searchKeywords = {
      keywords: escapedSearchWord,
      redirectUrl: this.#checkRedirection(this.#bffProductsInfo), // TODO: 先暂时自己生产直达链接的逻辑，后面转到bff
      origin_words: escapedSearchWord,
      origin_total: origin_num,
      is_suggest_res: Number(is_suggest_res) || 0,
      suggest_words,
      double_lang_correct: double_lang_correct === '1' ? true : false,
      suggest_total: suggest_words ? this.data.num : 0,
    }
  }
  // 构造searchKeywords - E

  // 构造goods
  #adaptGoods() {
    if (!this.#hasBffProductsInfo()) return

    const cccFeedback = this.#cccFeedbackInfo?.extendConfigDetail?.find((item) => {
      const dataPageStyleKey = this.data.cat_info.pageStyleKey || this.data.cat_info.pageName
      return item?.pageStyleKey === dataPageStyleKey && item.sceneKey === 'TWO_IN_A_ROW'
    })

    // 是否搜索结果页
    const isSearchPage = this.data.cat_info?.type === 'search'

    // 店铺搜索页
    const storeSearch = this.data.cat_info?.search_type === 'store'
    // 品牌搜索页
    const brandSearch = this.data.cat_info?.search_type === 'brand'

    this.data.goods = this.#bffProductsInfo.products?.map?.(v => {
      if (cccFeedback) {
        v.cccFeedback = cccFeedback
      }
      const goodsInfo = goodsTransformation(v, {
        suggestedSaleType: this.#bffProductsInfo?.suggestedSaleType || '',
        language: this.data.language ?? {},
        lang: this.#lang,
        isPaid: !!this.data.sheinClubInfo?.isPaid
      })

      // 品牌搜索&店铺搜索。中间层返回了，但需要屏蔽的功能
      if (isSearchPage && (brandSearch || storeSearch)) {
        if (goodsInfo.showAddButtonLabelStyle) {
          goodsInfo.showAddButtonLabelStyle = ''
        }
      }
      return goodsInfo
    }) ?? []
  }

  // 构造cardConfig(商卡配置)
  #adaptCardConfig() {
    if (!this.#hasBffProductsInfo() || !this.data.cardConfig) return

    // 标题相关配置
    const goodsNameLineLimit = Number(this.#bffProductsInfo?.listStyle?.twoColumnStyle?.goodsNameLineLimit)
    if ([1, 2].includes(goodsNameLineLimit)) {
      this.data.cardConfig.showTitle = !!goodsNameLineLimit
      this.data.cardConfig.showTitleTwoLine = goodsNameLineLimit === 2
    }

    // 加车按钮是否显示
    const bffGoodsInfo = this.#bffProductsInfo?.products?.[0] ?? {}
    if (bffGoodsInfo?.productMaterial?.showAddButtonLabel) {
      this.data.cardConfig.showAddBagBtn = bffGoodsInfo.productMaterial.showAddButtonLabel === '1'
    }

    // 临时兜底 如果没有返回商品 无结果 则把加车按钮显示出来
    const { page, type } = this.data?.cat_info ?? {}
    if (type === 'search' && page === 1 && !bffGoodsInfo?.productMaterial && this.data?.cardConfig) {
      this.data.cardConfig.showAddBagBtn = true
    }

    // urlQuery相关配置
    if (!this.data.cardConfig.urlQuery) this.data.cardConfig.urlQuery = {}
    this.data.cardConfig.urlQuery.pageListType = this.data.cat_info.pageListType
  }

  // 构造筛选（图文，分类/属性/tsp筛选，标签云）
  #adaptFilters() {
    if (!this.#hasBffFilterInfo()) return

    searchFilterAdapter(this.data, this.#bffFilterInfo)
  }
  
  // 构造filterAbtParam - S
  #formatChildAbt(listFilterAbt, currentId) {
    const isFlag = listFilterAbt?.p?.hasOwnProperty(currentId)
    let child = []
    if (isFlag) {
      const originChild = listFilterAbt.child || []
      child = originChild.filter(item => (item.param || item.p || {}).hasOwnProperty(currentId))
    }
    return Object.assign({}, listFilterAbt || {}, { child, a: 1 })
  }
  
  #adaptFilterAbtParam() {
    if (!this.#hasBffFilterInfo()) return

    if (!this.data.filterAbtParam) this.data.filterAbtParam = {}
    const listFilterAbt = this.#bffFilterInfo?.webAbtInfo?.listFilterAbt
    const cateId = this.#bffProductsInfo?.cate_ids?.[0]
    const abtParamsJson = listFilterAbt?.['abt-params-json']
    const abtPoskey = listFilterAbt?.['abt-poskey']
    const child = listFilterAbt?.child
    const listFilterAbtFormat = {
      child,
      p: abtParamsJson,
      param: abtParamsJson,
      posKey: abtPoskey,
      poskey: abtPoskey,
      sceneStr: abtPoskey,
      version: 'v4',
    }
    this.data.filterAbtParam.abt = this.#formatChildAbt(listFilterAbtFormat, cateId)
  }
  // 构造filterAbtParam - E

  #adaptGoodsOtherParameters() {
    if (!this.#hasBffProductsInfo()) return

    // 下游返回的request_ext透传
    this.data.request_ext = this.#bffProductsInfo?.request_ext ?? {}
    this.data.cateIds = this.#bffProductsInfo.cateIds || []

    // 商品总数
    this.data.sum = this.#bffProductsInfo.num
    this.data.sumForPage = this.#bffProductsInfo.numForPage

    // 中间层过滤的商品id
    this.data.frontFilterGoods = this.#bffProductsInfo.frontFilterGoods
  }

  // 广告流插坑
  #adaptFlowListBanner() {
    if (this.data.bffFlowData?.flowListBanner) {
      // ssr水合
      return this.data.bffFlowData.flowListBanner
    }
    const _cccCommonComponentResult = this.data.cccCommonComponentResult?.info
    if (!_cccCommonComponentResult) {
      return
    }
    const cateLinks = {}
    const banners = (_cccCommonComponentResult.content || []).filter(contentItem => {
      return contentItem.blockKey === 'flowList_Banner'
    }).map(bannerItem => {

      (bannerItem.props?.items || []).forEach(item => {
        cateLinks[`${item.hrefType}_${item.hrefTarget}`] = item.webClickUrl
      })

      return {
        ...bannerItem,
        __comp_name: 'StaticImageCardController',
      }
    })

    if (banners.length === 0) {
      return
    }
    const adRecord = {
      cateLinks,
      abtBranch: _cccCommonComponentResult.abtBranch,
      channelId: _cccCommonComponentResult.channelId,
      id: _cccCommonComponentResult.id,
      pageType: _cccCommonComponentResult.pageType,
      templateId: _cccCommonComponentResult.templateId,
      templateKey: _cccCommonComponentResult.templateKey,
      content: banners,
    }
    return {
      context: adRecord,
      data: adRecord.content.reduce((total, curr) => {
        total[curr.placeHolderPosition - 1] = curr
        return total
      }, {}),
    }
  }

  #adaptCccCommonComponent() {
    const flowListBanner = this.#adaptFlowListBanner()
    this.data.bffFlowData = {
      ...(this.data.bffFlowData || {}),
      flowListBanner,
    }
    delete this.data.cccCommonComponentResult
  }

  // 构造插坑数据
  #adaptFlowData () {
    this.data.bffFlowData = {
      ...(this.data.bffFlowData || {}),
      isAdultRankSwitchBff: this.data.isAdultRankSwitchBff || this.data.cat_info?.isAdultRankSsrBff,
    }
  }
}


export default BffAdapter
