// 图文
function isJump (navs = []) {
  return navs.some(nav => nav.isCanJump === '1')
}

// goods_id -> goodsId
function formatPicTopNavGoodsId (navs = []) { 
  return navs?.map(nav => { 
    nav.goodsId = nav.goods_id ?? ''

    return nav
  })
}
 
function picTopNavAdapter ({ navs = [], tabs = [], list_nav_source = '' }, multiTabsPtnClose = false) {
  // 多tabs
  if (tabs?.length) {
    // TODO 临时方案 当apollo配置为true时 只展示第一个tab
    if (multiTabsPtnClose) {
      tabs = tabs.slice(0, 1)
    }
    return {
      picTopNav: tabs.map(tab => ({
        ...tab,
        navs: formatPicTopNavGoodsId(tab?.navs),
        is_blank: isJump(tab?.navs),
        tabId: tab.tab_id,
        tabName: tab.tab_name,
        tabType: tab.tab_type,
        signType: 'list',
      })),
      list_nav_source,
    }
  }
  return {
    picTopNav: navs?.length
      ? [{
        'tabId': '0',
        'signType': 'list',
        'is_blank': isJump(navs),
        navs: formatPicTopNavGoodsId(navs),
      }]
      : [],
    list_nav_source,
  }
}

// 价格
function priceAdapter ({ min_price = '', max_price = '' }) {
  return {
    filterPrices: { min_price, max_price }
  }
}

// category 格式化children为空场景
function formatCategoriesChildren (categories = []) { 
  categories.forEach(cate => { 
    cate.children = cate.children ?? []
    if (!cate.children.length) return
    formatCategoriesChildren(cate.children)
  })
}

// category
function categoryAdapter ({ cat_info = {} }, { cat_path = [], categories = [], all_categories = [] }) {
  if (cat_info?.showAllCategory === '1' && all_categories?.length) { 
    formatCategoriesChildren(all_categories)
    return {
      filterCates: all_categories
    }
  }

  let filterCates = []
  const catLength = cat_path.length
  if (!catLength) { 
    filterCates = categories
  } else {
    const root = { children: [] }
    cat_path.reduce((acc, cat, index) => {
      cat.children = index == catLength - 1 ? categories : []
      acc.children = [cat]
      filterCates.push(cat)
      return cat
    }, root)
  }

  formatCategoriesChildren(filterCates)
  return {
    filterCates
  }
}

// filterList -> filterAttrs + filterTsps
function filterListAdapter ({ filterList }) {
  const { tsps, attrs } = filterList.reduce((acc, attr) => {
    const isTsp = attr.nodeType.toString() === '4' && attr.children.every(child => child.nodeType.toString() === '7')
    if (isTsp) {
      attr.children.length && acc.tsps.push(attr)
    } else {
      attr.children.length && acc.attrs.push(attr)
    }
    return acc
  }, {
    tsps: [],
    attrs: []
  })

  return {
    filterAttrs: formatAttr(attrs),
    filterTsps: formatTsp(tsps)
  }
}

const formatTsp = (tsps) => {
  return tsps.map(tsp => {
    const getName = tsp => tsp.nodeName
    return {
      tagGroupId: tsp.nodeId,
      tagGroupName: getName(tsp),
      nodeType: tsp.nodeType,
      nodeId: tsp.nodeId,
      tagList: tsp.children.map(tag => ({
        tagId: tag.nodeId,
        tagName: getName(tag),
        nodeType: tag.nodeType,
        nodeId: tag.nodeId,
      }))
    }
  })
}

const getI18nName = (node) => {
  return node.nodeName
}

const isNodeType = (node, type) => {
  if (Array.isArray(type)) {
    return type.some(t => node.nodeType.toString() === t.toString())
  }
  return node.nodeType.toString() === type.toString()
}

// type=2, 属性项id_属性值id(78_781); type=3, 属性项id_属性组id(27_5)。
const getAttrId = (value) => isNodeType(value, 2) ? value.nodeId.split('_')[0] : value.nodeId
const getAttrFilter = (node) => {
  if (isNodeType(node, 3)) {
    return node.attr_filter
  }
  return node.nodeId
}

const formatAttr = (attrs) => {
  const formatAttrValues = (values) => {
    return values.map(value => {
      const attr_id = parseInt(getAttrId(value))
      return {
        attr_filter: getAttrFilter(value),
        attr_id,
        attr_value: getI18nName(value),
        attr_values: getAttrValue(value),
        attr_image: value?.nodeImg || '',
        nodeType: value.nodeType,
        nodeId: value.nodeId,
      }
    })
  }
  
  const formatAttrGroups = (groups) => {
    return groups.map(group => {
      const attr_id = parseInt(getAttrId(group))
      return {
        attr_filter: getAttrFilter(group),
        attr_id,
        group_image: group.nodeImg,
        group_name: getI18nName(group),
        nodeType: group.nodeType,
        nodeId: group.nodeId,
      }
    })
  }

  const getAttrValue = (node) => {
    if (Array.isArray(node.children) && !isNodeType(node, 3)) {
      return formatAttrValues(node.children)
    }
    return []
  }

  return attrs.map(attr => {
    const oldAttr = {
      attr_id: attr.nodeId,
      attr_name: getI18nName(attr),
      show_group: attr.showGroup,
      nodeType: attr.nodeType,
      nodeId: attr.nodeId,
      attr_values: [],
      groups: [],
    }
    const isGroup = attr.showGroup && attr.nodeType.toString() === '1'
    if (isGroup) {
      // only group got this field
      oldAttr.show_group_txt = false
      oldAttr.groups = formatAttrGroups(attr.children || [])
    } else {
      oldAttr.attr_values = formatAttrValues(attr.children || [])
    }

    return oldAttr
  })
}

// bannerTag 
function bannerTagAdapter ({ bannerTag = {} }) { 
  return {
    bannerTag: {
      'tag_id': bannerTag?.tag_id ?? '',
      'showTag': bannerTag?.hasOneClickTag === '1' ? 1 : 0,
      'billno': bannerTag?.billno ?? '',
      'type': 'oneClickPay'
    }
  }
}

// attribute -> filterAttrs + filterTsps
function attributeAdapter ({ attribute = [] }) { 
  const filterAttrs = []
  const filterTsps = []

  attribute.forEach(item => { 
    // tsp
    if (item.attrType == 1) {
      const index = filterTsps.findIndex(obj => obj.tagGroupId == item.attr_id)
      const tsp = filterTsps[index]
        || {
          tagGroupId: item.attr_id,
          tagGroupName: item.attr_name,
          tagList: []
        }
      tsp.tagList.push({
        tagId: item.attr_filter,
        tagName: item.attr_name,
      })
      index < 0 && filterTsps.push(tsp)
    } else if (item.show_group == 1) {
      // group 
      const index = filterAttrs.findIndex(obj => obj.attr_id == item.attr_id)
      const attr = filterAttrs[index]
        || {
          attr_id: item.attr_id,
          attr_name: item.attr_name,
          attr_values: [],
          groups: [],
          show_group: 1
        }
      attr.groups.push({
        'attr_filter': item.attr_filter,
        'attr_id': item.attr_id,
        'group_name': item.group_name,
        'group_image': item.group_image,
      })
      index < 0 && filterAttrs.push(attr)
    } else {
      // attr
      const index = filterAttrs.findIndex(obj => obj.attr_id == item.attr_id)
      const attr = filterAttrs[index]
        || {
          attr_id: item.attr_id,
          attr_name: item.attr_name,
          attr_values: [],
          groups: [],
          show_group: 0
        }
      attr.attr_values.push({
        'attr_filter': `${item.attr_id}_${item.attr_value_id}`,
        'attr_id': item.attr_id,
        'attr_value': item.attr_value,
        'attr_image': '',
      })
      index < 0 && filterAttrs.push(attr)
    }
  })

  return {
    filterAttrs,
    filterTsps
  }
}

// tags -> cloudTags
function tagsAdapter ({ tags = [] }) { 
  const cloudTags = []
  tags?.forEach(tag => { 
    cloudTags.push({
      'tag_id': tag.tag_id,
      'tag_name': tag.tag_name,
      'type': tag.type,
      'tag_type': tag.tag_type,
      'is_sale_tag': tag.is_sale_tag,
      'promotionInfo': tag.promotionInfo || {},
      'value': tag.tag_id,
      'label': tag.tag_name,
      'local_mall': tag.is_local_mall,
      'quickship_tag': tag.tag_id == 'quickship',
      quickShipText: tag.tag_id == 'quickship' && tag.tag_name,
    })
  })

  return {
    cloudTags
  }
}

// cccxFilterConfig
function cccxFilterConfigAdapter (shouldUseCccxFilterChain, { filterSortConfigs = [] }) { 
  return {
    cccxFilterConfig: {
      filterRule: shouldUseCccxFilterChain ? filterSortConfigs : []
    }
  }
}

// cccConfig: tagCloudSlotConfig
function tagCloudSlotConfigAdapter ({ tagSlotConfigs = [] }) { 
  tagSlotConfigs?.forEach(item => { 
    item.tagId = item.tag_id
    item.tagName = item.tag_name
  })
  return {
    tagCloudSlotConfig: tagSlotConfigs ?? []
  }
}

// cccfilter: productTypeSortConfig
function productTypeSortConfigAdapter ({ tags = [] }) { 
  const productTypeSortConfig = []
  tags?.forEach(tag => {
    let tagType = tag.tag_type
    let isPush = true // 是否push到productTypeSortConfig[]
    if (tag.type === 'quickship') {
      tagType = 12
      isPush = productTypeSortConfig.findIndex(obj => obj.tagType == 12) < 0
    } else if (tag.type === 'mall') { 
      tagType = 11
      isPush = productTypeSortConfig.findIndex(obj => obj.tagType == 11) < 0
    } else if (tag.is_sale_tag === 100) { 
      tagType = 13
      isPush = productTypeSortConfig.findIndex(obj => obj.tagType == 13) < 0
    }

    const obj = {
      ...tag,
      tagType,
      tagId: tag.tag_id,
      tagName: tag.tag_name,
    }

    isPush && productTypeSortConfig.push(obj)
  })

  return {
    productTypeSortConfig
  }
}

// kids
function kidTagAdapter ({ tagChild = {} }) { 
  if (tagChild?.isShowKidsProfile !== '1') return {}
  const { child = [], defaultChild = {} } = tagChild


  return {
    defaultChild,
    kidsTag: {
      child_list: child.map(item => ({
        'child_id': item.child_id,
        'nickname': item.nickname,
        'birthday': item.birthday,
        'sex': item.sex
      }))
    },
  }
}

function adaptDailyDates(data) {
  const daily = data?.dailyDates || []
  if (!daily?.length) return
  let dailyDates = {}
  daily.forEach(item => {
    dailyDates[item.date] = item.show_date
  })
  return dailyDates
}

function searchFilterAdapter (sourceData, bffFilterInfo) {
  // 优先处理cccConfig / cccxFilterConfig，避免node层相关配置影响
  sourceData.cccConfig = Object.assign(
    sourceData.cccConfig || {},
    tagCloudSlotConfigAdapter(bffFilterInfo),
    productTypeSortConfigAdapter(bffFilterInfo),
  )
  
  const { filterList, filterSortConfigs } = bffFilterInfo
  const shouldUseCccxFilterChain = filterList?.length && filterSortConfigs?.length
  const { filterAttrs, filterTsps } = shouldUseCccxFilterChain ? filterListAdapter(bffFilterInfo) : attributeAdapter(bffFilterInfo)

  const multiTabsPtnClose = sourceData?.apolloConfig?.search_pic_top_nav_multi_tabs_close

  Object.assign(
    sourceData,
    kidTagAdapter(bffFilterInfo),
    picTopNavAdapter(bffFilterInfo, multiTabsPtnClose),
    priceAdapter(bffFilterInfo),
    categoryAdapter(sourceData, bffFilterInfo),
    bannerTagAdapter(bffFilterInfo),
    tagsAdapter(bffFilterInfo),
    cccxFilterConfigAdapter(shouldUseCccxFilterChain, bffFilterInfo),
    {
      filterTsps,
      filterAttrs,
      shouldUseCccxFilterChain,
      dailyDates: adaptDailyDates(bffFilterInfo)
    },
  )
}

export default searchFilterAdapter
